import React from 'react'
import styles from './gritzoIndia.module.scss';
import { convertToModule } from 'factory/utils/clientUtils';
import HK from 'factory/modules/HK';
import OwlCarousel from 'utility/templates/owlCarousel';
import { useState } from 'react';
import PreviewModal from './PreviewModal';

const index = ({data,title}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  let data2 = [
    {
      img: HK.getImage('/gritzoindia/gi_1.jpg'),
      img2:HK.getImage('/gritzoindia/gi_1_modal.jpg'),
      caption: "Wondering why protein is so important for your child’s growth? 🤔 Let’s break it down! 💪✨...(Trending, reel, Gritzo SuperMilk, Kids Nutrition, Parenting tips)",
      date: "September 27",
      link: "https://www.instagram.com/reel/DAa8y3ISy3r/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D"
    },
    {
      img: HK.getImage('/gritzoindia/gi_2.jpg'),
      img2:HK.getImage('/gritzoindia/gi_2_modal.jpg'),
      caption: "Although nutrition-week has ended but continue your journey towards providing the right nutrition for your child. Here are 5 expert tips from the Gritzo team. Swipe ??...#gritzo #supermilk #nutritionist #childnutrition #childnutritionist #kidsnutrition",
      date: "September 19",
      link: "https://www.instagram.com/reel/DAGZiM0SyO4/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D"
    },
    {
      img: HK.getImage('/gritzoindia/gi_3.jpg'),
      img2:HK.getImage('/gritzoindia/gi_3_modal.jpg'),
      caption: "If you are still giving your child malt-based health drinks, this is for you! Swipe ?? .      .      .      #Gritzo #Supermilk #Gritzo #KidsNutrition #Personalized #malt #maltdrinks  #kidshealth",
      date: "September 11",
      link: "https://www.instagram.com/gritzoindia/reel/DA_FCrez0YJ/?hl=en"
    }
  ]

  const handleModal = (ele, index) => {
    setActiveIndex(index);
    setIsModalOpen(true);
    // HK.noScroll(true)

  }
  

  const closeModal = () => {
    setActiveIndex(0);
    setIsModalOpen(false);
    // HK.noScroll(false);
  }
  const responsiveSliderSettingsNextD = {
    992: {
      slidesPerView: HK.config.slider.getSlidesNo(data2, 4, 2.2),
    },
}


  return (
    <div className='hk-pagecontainer'>
      <div className={convertToModule(styles, "gritzo-india hk-pagecontainer BS-products ")}>
        <div className={convertToModule(styles, "gritzo-india-title")}>
          <h2> Follow us @ gritzoindia</h2>
        </div>

        <div className={convertToModule(styles, "main-card-container")}>
          {<OwlCarousel
           wrapperClass="Shop-Flav-D"
           margin={0}
           items={4}
           slideBy={HK.config.slider.getSlidesNo(data2, 2, 2.5)}
           dots={false}
           nav={HK.isMobile() ? false : true}
           loop={false}
           responsive={true} //todoS 
           breakpoint={responsiveSliderSettingsNextD}
          >
            
            {
              // data && data.scItms && data.scItms.length > 0 && data.scItms.map((ele, index) => {
                data2.map((ele, index) => {
                return (
                  <blockquote className={convertToModule(styles, "img-card-conta")} onClick={() => handleModal(ele, index)}>
                  <img  src={ele.img} alt='img' />
                  <div style={{width: '100%', height: '95%',borderRadius:'10px'}} className={convertToModule(styles, "instafeed-overlay")}></div>
                </blockquote>
              // <script async src="//www.instagram.com/embed.js"></script>

                )
              })
            }
          </OwlCarousel>

          }
        </div>
      </div>
      {/* {isModalOpen && data && data.length > 0 && <PreviewModal data={data[activeIndex]} setActiveIndex={(idx)=>setActiveIndex(idx)} activeIndex={activeIndex} dataLen={data.length} closeModal={closeModal}/>} */}
      {isModalOpen && data2.length > 0 && <PreviewModal data={data2[activeIndex]} setActiveIndex={(idx)=>setActiveIndex(idx)} activeIndex={activeIndex} dataLen={data2.length} closeModal={closeModal}/>}
    </div>
  )
}

export default index