import React, { useEffect, useState } from "react";
import styles from './NPSCTA.module.scss';
import HK from "factory/modules/HK";
import { connect } from "react-redux";
import { LatestOrderAction } from "components/Home/store/action";
import { withRouter } from 'next/router';
import { __dateAfter, __isCallBackExist } from "factory/modules/HK_service";

const NPSCTA = ({ npsCaptchVisibility, npsCaptchValidate, ...props }) => {
    const [closeSelf, setCloseSelf] = useState(false);
    const getMyOrderData = () => {
        HK.loader(true);
        let reqObj = {
            method: "get",
            url: HK.config.APIS.myAccount.myOrder + "?pageNo=" + 1 + "&perPage=10&deliveryInfo=true&&orderDateFilter=Last 1 Month",
        };

        HK.ajaxCall(reqObj).then((res) => {
            if (res && res.data && res.data.results && !res.data.results.exception) {
                let tempOrderObject = [];
                const today = new Date();
                if (res.data.results.orders && res.data.results.orders.length > 0) {
                    res.data.results.orders.forEach(eachOrder => {
                        if (eachOrder.orderStatus === 'Delivered') {
                            let deliverydate = null;
                            if (eachOrder.oprLineItems && eachOrder.oprLineItems.length > 0) {
                                deliverydate = eachOrder.oprLineItems[0].deliveryDate;
                            }
                            if (deliverydate) {
                                deliverydate = new Date(deliverydate);
                                const startDate = __dateAfter(deliverydate, 5);
                                const endDate = __dateAfter(deliverydate, 14);
                                if (deliverydate && ((today >= startDate) && today <= endDate)) {
                                    tempOrderObject.push(eachOrder);
                                }
                            }

                        }
                    });
                    if (tempOrderObject && tempOrderObject.length > 0) {
                        const sortedObject = tempOrderObject.sort((a, b) => new Date(a.orderDt - b.orderDt));
                        props.dispatch(LatestOrderAction(sortedObject[0]));
                        __isCallBackExist(npsCaptchVisibility) && npsCaptchVisibility(true);
                    }

                }
            }
        }).catch((err) => {
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: err
            });
        }).finally(() => {
            HK.loader(false);
            __isCallBackExist(npsCaptchValidate) && npsCaptchValidate();
        });
    };

    useEffect(() => {
        if (props._latestOrderReducer && !props._latestOrderReducer.OrderDetails && props._isLogedInReducer) {
            getMyOrderData();
        }
    }, [props._isLogedInReducer]);

    const handleAddReviewButton = () => {
        props.router.push(`/feedback/Feedback.action?gatewayOrderId=${props._latestOrderReducer.OrderDetails.gId}`);
    };
    const handleClose = () => {
        setCloseSelf(true);
        __isCallBackExist(npsCaptchVisibility) && npsCaptchVisibility(false);
    };

    return (
        <>
            {props._latestOrderReducer && props._latestOrderReducer.OrderDetails && props._latestOrderReducer.OrderDetails.gId && !closeSelf ?
                <div className={styles['nps-cta-parent-container']}>

                    <div className={styles['body-cntnr']}>
                        <div className={styles["red-circle"]}>
                            <img src={HK.getImage('/Homepage/star.gif')} className={styles['star-icon']} />
                        </div>

                        <div className={styles['ttl-details-cntnr']}>
                            <div className={styles["ttl"]}>
                                Share your feedback
                            </div>

                            <div className={styles['dtls']}>
                                on your recent order!
                            </div>
                        </div>

                        <button type="button" className={styles['rate-now-btn']} onClick={() => handleAddReviewButton()}>
                            Rate Now
                        </button>

                        <svg onClick={() => { handleClose(); }} width="18" height="18" viewBox="0 0 18 18" fill="none" className={styles['close-icon']}>
                            <circle cx="9" cy="9" r="9" fill="#EAEAEA" />
                            <path d="M12 12L9 9M9 9L12 6M9 9C6.6 11.4 6 12 6 12L9 9ZM9 9C6.6 6.6 6 6 6 6" stroke="#999999" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                : <></>}
        </>
    );
};
// Take value from store
function mapStateToProps(state) {
    return {
        _latestOrderReducer: state.LatestOrderReducer,
        _isLogedInReducer: state.isLogedInReducer
    };
}
export default withRouter(connect(mapStateToProps, null)(NPSCTA));