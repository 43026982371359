import React, { Component } from 'react';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from "./TBox.module.scss";
import HK from 'factory/modules/HK';

class TBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    tabClick = () => {
        const { tabClickHandler, data } = this.props;
        tabClickHandler(data);
    }

    render() {
        const { tabName, tabImg, active } = this.props;
        return (
            <div className={convertToModule(styles, `f1-inner-tab ${active ? 'active' : ''}`)} onClick={this.tabClick}>
                <span>{tabName}</span>
                {/* <img src={tabImg} /> */}
                <img src={HK.getImage(tabImg)} />
                {/* <img src={HK.getImage('/newGritzo/meter.svg')} /> */}
            </div>
        )
    }
}

export default TBox;