import React, { Component } from 'react';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from "./TabsBox.module.scss";
// import HK from 'factory/modules/HK';
import TBox from './TBox';

class TabsBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: false
        }
    }

    componentDidMount() {
        //
        // const { tabsData } = this.props;
        // if (tabsData && tabsData.length > 0) {
        //     this.setState({ activeTab: tabsData[0].nm });
        // }
    }

    tabClickHandler = (data) => {
        this.setState({ activeTab: data.nm });
        this.props.clickHandler(null, null, data);
    }

    render() {
        const { tabsData } = this.props;
        return (
            <div className={convertToModule(styles, "tabs-box")}>
                <div className={convertToModule(styles, "tabs-box-inner HK-pagecontainer")}>
                    <div className={convertToModule(styles, "sf-filter-1")}>
                        <div className={convertToModule(styles, "f1-inner-tabs")}>
                            {
                                tabsData && tabsData.length > 0 &&
                                tabsData.map((ele, k) => {
                                    return <TBox
                                        key={k}
                                        data={ele}
                                        tabName={ele.nm}
                                        tabImg={ele.img}
                                        active={this.state.activeTab === ele.nm}
                                        tabClickHandler={this.tabClickHandler}
                                    />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TabsBox;