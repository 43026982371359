import React,{useState} from 'react'
import styles from './realMom.module.scss';
import { convertToModule } from 'factory/utils/clientUtils';
import HK from 'factory/modules/HK';
import OwlCarousel from 'utility/templates/owlCarousel';
import CardContainer from './CardContainer';
const index = () => {

    const [activeIndex,setActiveIndex] = useState(0)
    const [activeBorder,setActiveactiveBorder] = useState(true)

    let data = [
        {
            img: HK.getImage('/common/img1.avif'),
            data:{
                info:`My child was a little underweight and I was worried. My sister in law suggested Gritzo supermilk Weight+ and my 4 year old loved it. I started noticing her development after a month and have never looked back. It's a great product for children.`,
                name:'Anindita Rath',
                place:'Gurgaon',
            }
        },
        {
            img: HK.getImage('/common/img2.avif'),
            data:{
                info:`My 8 year old is fussy eater. It’s get difficult to make him eat properly. Neither he like milk. But when I saw Gritzo supermilk just tried without thinking as the product looks quite promising. And to my surprise my kid started to love milk. I am satisfied atleast he is having something and getting required nutrition`,
                name:'Swati Sharma',
                place:'Gujarat',
            }
        },
        {
            img: HK.getImage('/common/img3.png'),
            data:{
                info:`My 6 yr old daughter is very fond of Super milk active chocolate flavour as she likes the taste of itand moreover it's a sensible choice as it completes the nutritional requirements of a child and is natural and with no artificial flavour. Combining this with a balanced diet, and a child will have the best of health.`,
                name:'Pratima',
                place:'Delhi',
            }
        },
    ]

    const handleClick=(index)=>{
        setActiveIndex(index)
        setActiveactiveBorder(true)
    }

    return (
        <div className='hk-pagecontainer'>
            <div className={convertToModule(styles, "real-moms hk-pagecontainer BS-products ")}>
                <div className={convertToModule(styles, "real-moms-title")}>
                    <h2> Hear it from Real Moms</h2>
                </div>

                <div className={convertToModule(styles, "main-card-container")}>
                    {<OwlCarousel
                        className={"BS-Desk goal-section-container"}
                        margin={0}
                        items={HK.isMobile() ? 3: 3}
                        slideBy={1}
                        dots={HK.isMobile() ? false : false}
                        nav={false }
                        responsive={true}
                        autoplay={HK.isMobile() ? false : false}
                        autoplayTimeout={3500}
                        loop={true}
                    >
                        {
                            data && data.length > 0 && data.map((ele, index) => {
                                return (
                                    <div className={convertToModule(styles,  "item img_item")} onClick={()=>handleClick(index)}>
                                        <div className={convertToModule(styles, activeBorder && index === activeIndex  ?"momImgs show-brd" :"momImgs")}>
                                            <img src={ele.img} alt='img' />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>

                    }
                    {
                        data && data.map((ele,index)=>{
                            return(
                                   index === activeIndex && <CardContainer item ={ele}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default index