import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { pageTypeAction } from 'components/pageTypeAction';
import HK from 'factory/modules/HK';
import { connect } from 'react-redux';
import SkeletonPattern from 'utility/templates/skeletons/SkeletonPattern';
import HomeSlider from './HomeSlider';
import UniversalAnalytics from 'factory/modules/UniversalAnalytics.js';
import ShopFor from 'components/PageSections/ShopFor';
import GritzoIndia from 'components/PageSections/GritzoIndia';
import TabsWithVariants from 'components/PageSections/TabsWithVariants';
import VideosPageSection from 'components/PageSections/VideosPageSection';
import RealMoms from 'components/PageSections/RealMoms/index.js';
import NPSCTA from './NPSCTA';
import HomeStickyCTA from 'components/ReferAndEarn/HomeStickyCTA';
import ReferVars from 'components/ReferAndEarn/referandearnconfig';
import { __getCookie, __setCookie } from 'factory/modules/HK_service';
const AwardSection = dynamic(() => import('./LastSection'), { ssr: false });
const MBFeatureBanner = dynamic(() => import('./MBFeatureBanner/MBFeatureBanner'), { ssr: false });
const SeoContent = dynamic(() => import('./SeoContent.jsx'), { ssr: false });
const ShopBy = dynamic(() => import('./ShopBy/index.js'), { ssr: true });
const HomeLayout = ({ ...props }) => {
    const [remainData, set_remainData] = useState(null);
    const [mountTime, set_mountTime] = useState(null);
    const [showReferFlag, set_showReferFlag] = useState(false);
    const [isNPSCTA, set_isNPSCTA] = useState(false);
    const [isNPSValidate, set_isNPSValidate] = useState(false);
    useEffect(() => {
        getRemainingData();
        triggerMoengage();
        let startDate = new Date();
        set_mountTime(startDate);
        setTimeout(() => {
            const isDisableRE = __getCookie('isDisableRE');
            if (!isDisableRE) {
                set_showReferFlag(true);
            }
        }, 500);
        return () => {
            if (mountTime) {
                let endDate = new Date();
                let seconds = Math.round((endDate.getTime() - mountTime.getTime()) / 1000);
                let clickStream = {
                    timerDuration: seconds
                };
                HK.triggerComponentUnloadEvent(clickStream);
            }
        };
    }, []);
    useEffect(() => {
        const openAppDom = document.getElementById('get-open-app-cta');
        if (openAppDom) {
            if (showReferFlag) {
                openAppDom.style.bottom = '105px';
            } else {
                openAppDom.style.bottom = '45px';
            }
        }
    }, [showReferFlag]);


    const updatepageType = () => {
        props.dispatch(pageTypeAction('home'));
    };

    const handleCloseReferEarnPopup = () => {
        const d = new Date();
        const duration = d.getDate() + 10;
        __setCookie("isDisableRE", true, duration, true);
        set_showReferFlag(false);
    };

    const getRemainingData = () => {
        let isUserIdRequired = '';
        let apiUrl = HK.config.APIS.home.secondFold;
        let reqObj = {
            method: "get",
            url: apiUrl
        };
        // eslint-disable-next-line no-unused-vars
        HK.ajaxCall(reqObj, isUserIdRequired = true).then((res) => {
            if (!res.data.results.exception) {
                const obj = { ...res.data.results };
                const result = res.data.results;
                const topVideos = [], bottomVideos = [];
                if (result && result.page && result.page.videoContent && result.page.videoContent.length > 0) {
                    const videos = result.page.videoContent;

                    videos.forEach((item) => {
                        if (item.identifier && item.identifier.toLowerCase() === 'bottom') {
                            bottomVideos.push(item);
                        } else {
                            topVideos.push(item);
                        }
                    });
                }

                obj["topVideos"] = topVideos;
                obj["bottomVideos"] = bottomVideos;
                set_remainData(obj);
                updatepageType();
                let promotions = [];
                props.homeSliderItems && props.homeSliderItems.map((d, i) => {
                    let name = d.title;
                    let position = i + 1;
                    return promotions.push({ name, position });

                });
                let promotionImpression = {
                    'event': 'LS_MB_promotionImpression',
                    'ecommerce': {
                        'promoView': {
                            promotions
                        }
                    }
                };
                let homePageViewEvent = {
                    ...promotionImpression
                };
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(homePageViewEvent);
            } else {
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                });
            }
        }).catch((err) => {
            console.log(err);
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: err
            });
        });
    };

    const componentRendering = (elem, indx) => {
        const { type, pgDesignTyp, /* dis_nm, nm */ } = elem;
        const { PAGE_SECTION_TYPE: TYPE, PAGE_SECTION_DESIGN_TYPE: DESIGN_TYPE } = HK.config;
        if (type === 15 && pgDesignTyp === 1) {
            elem.positionIdx = indx;
            return <ShopBy data={elem} key={indx} title={elem.dis_nm} isMobile={props.isMobile} />;
        } else if (type === 15 && pgDesignTyp === 35) {
            elem.positionIdx = indx;
            return <ShopFor data={elem} key={indx} title={elem.dis_nm} isMobile={props.isMobile} {...props} />;
        }
        else if (type === 52 && pgDesignTyp === 1) {
            elem.positionIdx = indx;
            return <TabsWithVariants data={elem} key={indx} title={elem.dis_nm} isMobile={props.isMobile} {...props} />;
        }
        else if (type === 15 && pgDesignTyp === 68) {
            return <GritzoIndia data={elem} key={indx} title={elem.dis_nm} isMobile={props.isMobile} />;
        }

        else if (type === 18 && pgDesignTyp === 35) {
            return <VideosPageSection data={elem} key={indx} title={elem.dis_nm} isMobile={props.isMobile} />;
        }
        //Safe and Secure 
        else if (type === 52 && pgDesignTyp === 54) {
            return <MBFeatureBanner key={indx} isMobile={props.isMobile} />;
        }
        //Last section
        else if (type === TYPE.HOME_PAGE.LAST_SECTION && pgDesignTyp === DESIGN_TYPE.HOME_PAGE.POPULAR_CAT) {
            return <AwardSection data={elem} key={indx} isMobile={props.isMobile} />;
        }

        return null;
    };

    const triggerMoengage = () => {
        UniversalAnalytics.HOMEPAGE();
    };
    const npsCaptchVisibility = (value = false) => {
        set_isNPSCTA(value);
    };
    const npsCaptchValidate = () => {
        set_isNPSValidate(true);
    };

    const _homeBannerSlider = [];
    const pgBnrSlots = props.pageData && props.pageData.pgBnrSlots && props.pageData.pgBnrSlots.length > 0 && props.pageData.pgBnrSlots[0];

    pgBnrSlots && pgBnrSlots.images && pgBnrSlots.images.map((v) => {
        return _homeBannerSlider.push({
            link: v.lp,
            title: v.cap,
            img: v.o_link,
            id: v.imageId
        });
    });
    return (
        <div className="hk-home-container">
            {
                props.homeSliderItems.length > 0 ?
                    <HomeSlider items={_homeBannerSlider} isMobile={props.isMobile} /> : <SkeletonPattern pattern={[11]} />
            }
            {
                props.pageData && props.pageData.pgSections && props.pageData.pgSections.length > 0 ? props.pageData.pgSections.map((ele, i) => {
                    return componentRendering(ele, i);
                }) :
                    props.isMobile ? <SkeletonPattern pattern={[8]} /> :
                        <SkeletonPattern pattern={[13]} />
            }
            {
                remainData ? remainData.page.pgSections.map((ele, i) => {
                    return componentRendering(ele, i);
                }) :
                    props.isMobile ? <SkeletonPattern pattern={[8]} /> :
                        <SkeletonPattern pattern={[13]} />
            }
            {
                <RealMoms />
            }
            {
                remainData && props.pageData && props.pgSeo && props.pgSeo.desc &&
                <SeoContent pgSeo={props.pgSeo} />
            }
            {HK.isMobile() ? <>
                {isNPSValidate && !isNPSCTA && showReferFlag && ReferVars.Visibility.isHome ?
                    <HomeStickyCTA handleCloseReferEarnPopup={handleCloseReferEarnPopup} />
                    : <NPSCTA npsCaptchVisibility={npsCaptchVisibility} npsCaptchValidate={npsCaptchValidate} />
                }
            </> : ''}
            {/* ########## nono used code */}
            {/* {
                    <GritzoIndia />
                } */}
            {/* {
                    <ShopFor {...props} />
                } */}

            {/* {
                    <VideosPageSection />
                } */}
        </div>
    );
};

export default connect(null, null)(HomeLayout);