import React, { Component } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
//C:\Users\jai.1\Desktop\MBNextJs\ReactMaster\src\components\Cart\common\ApplyOffer\apply-offer.module.scss
//C:\Users\jai.1\Desktop\MBNextJs\ReactMaster\src\utility\templates\owlCarousel.js
import styles from '../../components/Cart/common/ApplyOffer/apply-offer.module.scss';
import { convertToModule } from 'factory/utils/clientUtils';
// import "swiper/swiper.min.css";
import SwiperCore, {
    Pagination, Navigation, Autoplay
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);
class OwlCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _owlID: 'owl' + Math.floor(Math.random() * 100000)
        };
    }

    componentDidMount() {

    }

    render() {
        const { items, margin, dots, className, nav, loop, slideBy=1, /* reviewData, */ autoplay, autoplayTimeout, autoplayHoverPause, navSpeed, wrapperClass="",initialSlide=0, center=false, responsive=false, stopOnLastSlide, breakpoint, slidesPerViewProp } = this.props;
        return (
            <div className={convertToModule(styles,`hk-swiper ${wrapperClass}`)}>
                <Swiper
                    key={this.state._owlID}
                    slidesPerView={slidesPerViewProp || items}
                    spaceBetween={margin}
                    slidesPerGroup={slideBy}
                    loop={loop ? true : false}
                    loopFillGroupWithBlank={loop ? true : false}
                    pagination={dots ? { "clickable": dots } : false}
                    navigation={nav ? true : false}
                    // url={reviewData && reviewData?.pr_img?.l_link}
                    autoplay={ autoplay ? {
                        delay: autoplayTimeout,
                        disableOnInteraction: false,
                        stopOnLastSlide: stopOnLastSlide ? true : false,
                        pauseOnMouseEnter: autoplayHoverPause ? autoplayHoverPause : false
                        } : false
                    }
                    speed={navSpeed ? navSpeed : 750}
                    className={convertToModule(styles,className)}
                    observer={false}
                    observeParents={true}
                    initialSlide={initialSlide}
                    centeredSlides={center}
                    breakpoints={responsive ? (breakpoint != undefined ? breakpoint : {
                        678: {
                          slidesPerView: 3.3,
                        },
                      }) : false}
                >
                    {
                        this.props.children &&
                        this.props.children.map((ele, k) => {
                            return <SwiperSlide key={k}>{ele}</SwiperSlide>;
                        })
                    }
                </Swiper>
            </div>
        );
    }
}
export default OwlCarousel;