import HK from 'factory/modules/HK';
import React from 'react';
// import Slider from "react-slick";
import styles from './mwebHomeSlider.module.scss';
import { convertToModule } from 'factory/utils/clientUtils';
import Itrack from 'utility/Itrack';
import IntersectionObserver from '../../../common/IntersectionObserver'
import UniversalAnalytics from 'factory/modules/UniversalAnalytics.js'
import OwlCarousel from 'utility/templates/owlCarousel';
const MwebHomeSlider = ({ data }) => {
    if (!data) return null;

    const updateDatalayer = (eventAction, item, k) => {
        UniversalAnalytics.BANNER_CLICK(item, k + 1)
        let data = {
            'event': 'Is_MB_Home-Banners',
            'eventCategory': 'Home Page Banners',
            'eventAction': eventAction,
            'eventLabel': "",
        }
        HK._setDataLayer(data);
        let EventName = {
            'event': 'LS_MB_promotionClick',
            'ecommerce': {
                'promoClick': {
                    'promotions': [
                        {
                            'id': "",
                            'name': eventAction,
                            'creative': "",
                            'position': k + 1
                        }]
                }
            },
        }
        window.dataLayer.push(EventName)
        let clickStream = {
            widgetName: 'Carousel Banner',
            position: k + 1,
            eventName: "CAROUSEL_BANNER",
            itemName: item && item.title,
            landingUrl: item && item.link
        }
        HK.triggerComponentClickEvent(clickStream)
    }
    const triggerInViewEvent = (banner, index) => {
        return (inView, entry) => {
            if (!inView) return;
            const eventData = {
                widgetName: 'Carousel Banner',
                itemName: banner.title,
                position: index + 1,
                eventName: "CAROUSEL_BANNER",
                landingUrl: banner && banner.link
            }
            HK.triggerComponentViewEvent((eventData))
        }
    }

    const nextBtn = (
        <img src={HK.getImage('/slick-slider/slider1/next_v1.svg')} alt="next_v1.svg"/>
    )

    const prevBtn = (
        <img src={HK.getImage('/slick-slider/slider1/prev_v1.svg')} alt="prev.svg"/>
    )

    return (
        <section className={convertToModule(styles, "mweb-home-slider")}>
            <div className={convertToModule(styles, "slider-container")}>
                {/* <Slider
                    dots={true}
                    infinite={true}
                    arrows={false}
                    // speed={2000} 
                    slidesToShow={1}
                    slidesToScroll={1}
                    autoplay
                    autoplaySpeed={3000}
                    nav= {true}
                    nextArrow={nextBtn}
                    prevArrow={prevBtn}
                > */}
                <OwlCarousel
                    className="home-slider-M"
                    margin={0}
                    items={1}
                    slideBy={1}
                    dots={true}
                    nav={false}
                    loop={true}
                    autoplay
                    autoplayTimeout={3000}
                    slidesPerViewProp={'auto'}
                    // responsive={true}
                >
                    {
                        data.map((item, k) => {
                            return (
                                item.link ?
                                    <Itrack classname={convertToModule(styles, "banner-item")} key={k} onClick={updateDatalayer.bind(this, item.title, item, k)}
                                        ind={k + 1} isLink={HK.isLink(item.link)} isFullUrl url={item.link} id={item.title} childOf={'top-banner-slider'} >
                                        <IntersectionObserver key={k} onChange={triggerInViewEvent(item, k)}>
                                            <img className={convertToModule(styles, "banner-img")} src={item.img} alt={item.title} fetchpriority={k === 0 ? 'high' : 'auto'}/>
                                        </IntersectionObserver>
                                    </Itrack>
                                    :
                                    <div className={convertToModule(styles, "banner-item")} key={k} onClick={updateDatalayer.bind(this, item.title, item, k)}>
                                        <img className={convertToModule(styles, "banner-img")} src={item.img} alt={item.title} fetchpriority={k === 0 ? 'high' : 'auto'}/>
                                    </div>
                            )
                        })
                    }
                </OwlCarousel>
                {/* </Slider> */}
            </div>
        </section>
    );
}

export default MwebHomeSlider;
