import React,{useEffect} from 'react';
// import HK from 'factory/modules/HK';
import DesktopHomeSlider from './DesktopHomeSlider';
import MwebHomeSlider from './MwebHomeSlider';
import HK from 'factory/modules/HK';

const HomeSlider = (props) => {
    useEffect(() => {
        HK.chatIcon(false)
    }, [])
    return props.isMobile ? <MwebHomeSlider data={props.items} /> : <DesktopHomeSlider {...props} />
}

export default HomeSlider;