import React, { Component } from 'react';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from "./TabsLine.module.scss";
import HK from 'factory/modules/HK';
import TLTab from './TLTab';

class TabsLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: false
        }
    }

    componentDidMount() {
        const { tabsData } = this.props;
        if (tabsData && tabsData.length > 0) {
            this.setState({ activeTab: tabsData[0].dis_nm });
        }
    }

    tabClickHandler = (data) => {
        this.setState({ activeTab: data.dis_nm });
        this.props.clickHandler(null, null, data);
    }

    getImageFromName = (name) =>{
        let imgUrl = "/newGritzo/rocket.png";
        if(name === "Deals"){
            imgUrl = "/newGritzo/percentage.png"
        }else if(name === "New Launch"){
            imgUrl = "/newGritzo/rocket.png"
        }else if(name === "Best Seller"){
            imgUrl = "/newGritzo/badge.png"
        }
        return imgUrl;
    }

    render() {
        const { tabsData } = this.props;
        return (
            <div className={convertToModule(styles, "tabs-line")}>
                <div className={convertToModule(styles, "tabs-line-inner HK-pagecontainer")}>
                    <div className={convertToModule(styles, "sf-filter-2")}>
                        <div className={convertToModule(styles, "f2-inner-cont")}>
                            {
                                tabsData && tabsData.length > 0 &&
                                tabsData.map((ele, k) => {
                                    return <TLTab
                                        key={k}
                                        data={ele}
                                        tabImg={ele.img || this.getImageFromName(ele.dis_nm)}
                                        active={this.state.activeTab === ele.dis_nm}
                                        tabClickHandler={this.tabClickHandler}
                                        {...this.props}
                                    />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TabsLine;