import React, { useEffect } from 'react'
import styles from './productCard.module.scss';
import { convertToModule, convertImageCF } from 'factory/utils/clientUtils';
import HK from 'factory/modules/HK';
import AddToCart from 'utility/templates/Generic/AddToCart';
import ReviewStarts from 'components/ReviewRevamppdp/ReviewStars/index.jsx'
import BuyNow from 'utility/templates/Generic/BuyNow';

const GritzoProductCard = ({ data, url, title, eventName, widgetPosition, index, pageType, isWidth50 = false, isPack }) => {
    useEffect(() => {
        HK.chatIcon(false)
    }, [])

    const getAge = () => {
        let _age = "";
        if (data && data.grps && data.grps.length > 0) {
            data.grps.map(ele => {
                if (ele && (ele.nm === "gz-filter-pro" && ele.type === 1 && ele.dis_nm === "Gritzo Feature")) {
                    if (ele.values && ele.values.length > 0) {
                        ele.values.map(_data => {
                            if (_data && _data.nm === "gz-age") {
                                if (_data.val) {
                                    _age = _data.val.split(",")[0];
                                }
                            }
                        })
                    }
                }
            })
        }
        return _age;
    }

    const getFlavour = () => {
        let _flv = "";
        if (data && data.grps && data.grps.length > 0) {
            data.grps.map(ele => {
                if (ele.nm === "hfd-flavour-g" && ele.dis_nm === "Flavour") {
                    if (ele.values && ele.values.length > 0) {
                        ele.values.map(_data => {
                            if (_data.nm === "hfd-flavour") {
                                if (_data.val) {
                                    _flv = _data.val;
                                }
                            }
                        })
                    }
                }
            })
        }
        return _flv;
    }

    const getMRP = () => {
        if (data) {
            const _mrp = (data && data.mrp) || (data.sv_bsc && data.sv_bsc.mrp);
            if (_mrp && (_mrp !== data.offer_pr) && (_mrp !== data.offerPrice)) {
                return _mrp;
            }
        }
        return null;
    }

    const getDiscount = () => {
        if (data) {
            let _discount = data && data.discount;
            if (_discount) {
                return _discount;
            } else {
                _discount = data.sv_bsc && data.sv_bsc.discount;
                if (_discount) {
                    return Math.floor(_discount * 100);
                }
            }
        }
        return null;
    }

    const getImage = () => {
        let width = 400;
        let height = 400;
        if (HK.isMobile()) {
            width = 300;
            height = 300;
        }
        let imgUrl = (data.m_img && data.m_img.o_link) || data.img || (data.pr_img && data.pr_img.o_link) || (data.pckimage && data.pckimage.o_link);
        imgUrl = convertImageCF(imgUrl, width, height)
        return imgUrl;
    }
    
    return (
        <div className={convertToModule(styles, `${isWidth50 ? 'width-50' : 'width-100'} col-lg-4 col-sm-10 col-xl-auto product-card ${pageType && pageType !== undefined && pageType === "home" ? "home" : ""}`)}>
            <div className={convertToModule(styles, (pageType === "menuLanding" || "search") || (isWidth50) ? "product-card-wrapper no-padding-listing" : "product-card-wrapper")}>
                <div className={convertToModule(styles, `product-image-container ${pageType === 'home' ? 'home' : ''}`)}>
                    <a href={url || data.url} className={convertToModule(styles, "orange_background")}>
                        <img className={convertToModule(styles, "card-image")} src={getImage()} loading="lazy" alt={(data.m_img && data.m_img.alt) || (data.pr_img && data.pr_img.alt) || (data.pckimage && data.pckimage.alt) || "product-img"} title={(data.m_img && data.m_img.alt) || (data.pr_img && data.pr_img.alt) || (data.pckimage && data.pckimage.alt) || (data.alt) || "product-img"} />
                        {/* {data.freebie && data.freebie.length > 0 && <span><img style={{ display: 'block' }} id="secomapp-fg-image-6613038628996" src={HK.getImage('/Gritzo/freeGift.png')} alt="Free Gift Icon" className={convertToModule(styles, (pageType == "menuLanding" || "search") && pageType !== 'home' ? "sca-fg-img-collect width-50" : "sca-fg-img-collect")} /></span>} */}
                    </a>
                </div>

                <div className={convertToModule(styles, "product-detail")}>
                    <div className={convertToModule(styles, "star-rating")}>
                        {data && data.rating > 0 && <div className={convertToModule(styles, "star")}>
                            <ReviewStarts value={data.rating} isYellow={true} />
                        </div>
                        }
                        {data.nrvw > 0 && <div className={convertToModule(styles, "rating-brd")}></div>}
                        {data.nrvw > 0 && <div className={convertToModule(styles, "left-content")}>
                            <span class="spr-badge-caption">({data.nrvw} {data.nrvw > 1 ? 'Reviews' : 'Review'})</span>
                        </div>
                        }
                    </div>
                    <h2 className={convertToModule(styles, "product-name")} title={(data.m_img && data.m_img.alt) || (data.pr_img && data.pr_img.alt) || (data.pckimage && data.pckimage.alt) || (data.alt) || "product-img"}>
                        <a href={url} >
                            {data && (data.nm || data.dis_nm || data.name)}
                        </a>
                    </h2>
                    {
                        (getAge() || getFlavour()) ?
                            <div className={convertToModule(styles, "tag_flavour")} >
                                <p className={convertToModule(styles, "product_tag")} >{getAge()} |</p>
                                <p className={convertToModule(styles, "product_tag")} >&nbsp; {getFlavour()}</p>
                            </div>
                            :
                            <div className={convertToModule(styles, "no-flavours")}></div>
                    }

                    <div className={convertToModule(styles, "form-container")}>
                        <div className={convertToModule(styles, `product-card-footer`)} >
                            <div className={convertToModule(styles, "price-container")} >
                                <div className={convertToModule(styles, "product_price_details")} >
                                    {
                                        getMRP() &&
                                        <span className={convertToModule(styles, "product-sale-price")} data-compare-price="">
                                            ₹{getMRP()}
                                        </span>
                                    }
                                    {
                                        getDiscount() &&
                                        <span className={convertToModule(styles, "product-offer")} >
                                            ({getDiscount()}% Off)
                                        </span>
                                    }
                                </div>
                                <div className={convertToModule(styles, "price_select")}>
                                    <p className={convertToModule(styles, "product-price")}>₹{data && data.offer_pr || data.offerPrice}</p>
                                    {
                                        data && data.selAttr && data.selAttr["fn-att-quant"] &&
                                        <>
                                            <span className={convertToModule(styles, "slash")}>/</span>
                                            <div className={convertToModule(styles, "select-prod-container")}>
                                                <select className={convertToModule(styles, "select-option")} name="id" id="">
                                                    <option selected="selected">{data && data.selAttr && data.selAttr["fn-att-quant"]}</option>
                                                </select>

                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                            {
                                <div className={convertToModule(styles, `ctas ${pageType === "home" ? 'homepage' : ''}`)}>
                                    {pageType !== "home" ?
                                        <BuyNow
                                            id={data && (data.id || (data.sv_bsc && data.sv_bsc.sv_id) || (data.value))}
                                            isPack={isPack || (data && data.pk_type) || (data && data.url && data.url.indexOf('/pk') > -1 ? true : false)}
                                            vendorID={data && (data.vendorId || (data.sv_bsc && data.sv_bsc.vendorId))}
                                            varData={{
                                                id: (data && (data.id || (data.sv_bsc && data.sv_bsc.sv_id) || (data.value))) || "",
                                                name: data && data.nm || "",
                                                category: data && data.catName || "",
                                                offerPrice: data && data.offer_pr || 0,
                                                brName: data && data.brName || "",
                                                img: data && data.pr_img ? data && data.pr_img.o_link : "",
                                                widgetName: title || "",
                                                eventName: eventName,
                                                widgetPosition: widgetPosition + 2,
                                                itemPosition: index + 1
                                            }}
                                        />
                                        : <></>}
                                    <AddToCart
                                        wrapperClass={convertToModule(styles, "atc-custom")}
                                        id={data && (data.id || (data.sv_bsc && data.sv_bsc.sv_id) || (data.value))}
                                        isPack={isPack || (data && data.pk_type) || (data && data.url && data.url.indexOf('/pk') > -1 ? true : false)}
                                        vendorID={data && (data.vendorId || (data.sv_bsc && data.sv_bsc.vendorId))}
                                        event={"Is_MB_Home_Add-Buy"}
                                        eventCat={"Home Page"}
                                        eventAct={"Add to Cart"}
                                        varData={{
                                            id: (data && (data.id || (data.sv_bsc && data.sv_bsc.sv_id) || (data.value))) || "",
                                            name: data && data.nm || "",
                                            category: data && data.catName || "",
                                            offerPrice: data && data.offer_pr || 0,
                                            brName: data && data.brName || "",
                                            img: data && data.pr_img ? data && data.pr_img.o_link : "",
                                            widgetName: title || "",
                                            eventName: eventName,
                                            widgetPosition: widgetPosition + 2,
                                            itemPosition: index + 1
                                        }}
                                    />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GritzoProductCard