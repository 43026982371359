import { convertToModule } from 'factory/utils/clientUtils';
import styles from 'static/css/reviewRevamp/star.module.scss'
import React from 'react';
import HK from 'factory/modules/HK';

const StarCalc = ({ filledPercentage, onStarClick, isYellow }) => {
    return (
        <div className={convertToModule(styles, 'star-calc')} onClick={onStarClick}>
            <img src={HK.getImage('/review/unfilled-star.png')} alt="star" className={convertToModule(styles, 'empty')} />
            <div className={convertToModule(styles, `filled ${isYellow ? 'yellow' : ''}`)} style={{ width: `${filledPercentage}%` }}></div>
        </div>
    );
}

export default StarCalc;