import React, { Component } from 'react';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from "./TLTab.module.scss";
import HK from 'factory/modules/HK';

class TLTab extends Component {

    tabClick = () => {
        const { tabClickHandler, data } = this.props;
        tabClickHandler(data);
    }

    render() {
        const { data, tabImg, active, parentClass } = this.props;
        return (
            <div className={convertToModule(styles, `f2-inner-tab ${active ? 'active' : ''} ${parentClass || ""}`)} onClick={this.tabClick}>
                {/* <img src={tabImg} /> */}
                <img  src={HK.getImage(tabImg)} alt={data.dis_nm} />
                <span>{data.dis_nm}</span>
                {/* <img src={HK.getImage('/newGritzo/meter.svg')} />
                <span>Weight</span> */}
            </div>
        )
    }
}

export default TLTab;