export const HomeRouteInitAction = (data) => {
    return {
        type: "HOME_INIT",
        payload: data
    }
}

export const LatestOrderAction = (data) =>{
    return{
        type: 'LATEST_ORDER_ACTION',
        payload: data
    }
}