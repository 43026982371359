const ReferVars = {
    Visibility :{
        isHome: true,
        isBreadcrumbs: true,
        isPDPMwebView: true,
        isMenuView: true,
        isBannerWeb: true,
        isTopMweb:true
    }
};

export default ReferVars;