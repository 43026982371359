import { isLoginPopupAction } from 'components/common/login/action';
import { HomeRouteInitAction } from 'components/Home/store/action.js';
import HK from 'factory/modules/HK';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import SkeletonPattern from 'utility/templates/skeletons/SkeletonPattern';
import HomeLayout from './HomeLayout';
// import 'static/css/homeNew/homeNew.css';

class HomeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageDataLoaded: false,
            homeSliderItems: [],
            pgSeo: null,
            pageSection: null,
            ...this.props.homeSsrData
        };
    }
    openLoginPopup() {
        let redirectTo = ''
        this.props._isLoginPopupAction({ isShow: true, activeTab: "login", redirectTo: redirectTo });
        // this.props.history.push("/signIn?forgotpassword=true");
    }
    componentDidMount() {
        let canonicalUrl;
        canonicalUrl = {path: window.location.pathname}

        let args = {
            title: `Gritzo: Personalized Nutrition &amp; SuperMilk Protein Drinks for Kids`,
            metaDesc: `Gritzo`
        }
        HK._setSEO(args, canonicalUrl);

                let isForgotPasswrod = HK.getSearchParmas().forgotpassword;
        if (isForgotPasswrod && isForgotPasswrod == 'true') {
            // this.openLoginPopup();
        } else {
            // HK.chatIcon(true);
            // document.documentElement.scrollTop = 0;
            localStorage.removeItem('_datalayer');
            // this.doRouterInit();
            if (localStorage.getItem('thankuUser')) {
                this.setState({
                    showThanku: true
                })
                localStorage.removeItem('thankuUser')
            }
        }
    }
    componentWillUnmount = () => {
        HK._removeHreflang()
    }
    // doRouterInit = (scrollPosition) => {
    //     HK.routerInit('home').then((pageData, data) => {
            
    //         let _homeBannerSlider = [];
    //         const isMobile = HK.isMobile();
    //         this.props._HomeRouteInitAction(pageData)
    //         pageData.pgBnrSlots && pageData.pgBnrSlots.forEach((bannerSlot) => {
    //             if (bannerSlot.slot === 'BACKGROUND_SLOT') {
    //                 bannerSlot.images.map((v, k) => {
    //                     if (!isMobile) {
    //                         return _homeBannerSlider.push({
    //                             link: v.lp,
    //                             title: v.cap,
    //                             img: v.o_link
    //                         });
    //                     }
    //                 })
    //             } else if (bannerSlot.slot === 'MOBILE720') {
    //                 if (isMobile) {
    //                     bannerSlot.images.map((v, k) => {
    //                         return _homeBannerSlider.push({
    //                             link: v.lp,
    //                             title: v.cap,
    //                             img: v.o_link
    //                         });
    //                     })
    //                 }
    //             }
    //         })

    //         if (isMobile && (!_homeBannerSlider || _homeBannerSlider.length === 0)) {
    //             pageData.pgBnrSlots && pageData.pgBnrSlots.forEach((bannerSlot) => {
    //                 if (bannerSlot.slot === 'MOBILE360') {
    //                     bannerSlot.images.map((v, k) => {
    //                         return _homeBannerSlider.push({
    //                             link: v.lp,
    //                             title: v.cap,
    //                             img: v.o_link
    //                         });
    //                     })
    //                 }
    //             })
    //         }

    //         this.setState({
    //             pageDataLoaded: true,
    //             homeSliderItems: _homeBannerSlider,
    //             pageData: pageData,
    //             pgSeo: pageData ? pageData.pgSeo : null,
    //             pageSection: pageData ? pageData.pgSections : null
    //         });

    //         this.loadHomePage(scrollPosition);
    //     }).catch((error) => { });
    // }
    
    render() {
        return (
            this.state.pageDataLoaded ?
                <HomeLayout
                    pageData={this.state.pageData}
                    homeSliderItems={this.state.homeSliderItems}
                    // staticBanner={this.state.staticBanner}
                    pgSeo={this.state.pgSeo}
                    isMobile={this.props.isMobile}
                    {...this.props}
                /> : <SkeletonPattern pattern={[2, 8]} />
        );
    }
}
// Take value from store
function mapStateToProps(state) {
    return {
        isLoggedin: state.isLogedInReducer,
    };
}
// Store value
const mapDispatchToProps = {
    _HomeRouteInitAction: HomeRouteInitAction,
    _isLoginPopupAction: isLoginPopupAction
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HomeComponent)
);