import React from 'react';
import Star from './Star';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from './reviewStars.module.scss';

const ReviewStars = ({ value, isYellow }) => {
    const starsArray = []
    const decimal = parseInt(parseFloat(value).toFixed(1).toString().split('.')[1], 0)
    const ratingInt = parseInt(value)

    for (let i = 1; i <= 5; i++) {
        if (i <= ratingInt) {
            starsArray.push(<Star key={i} filledPercentage={100} isYellow={isYellow} />)
        }
        else if (i === ratingInt + 1 && decimal > 0) {
            starsArray.push(<Star key={i} filledPercentage={decimal * 10} isYellow={isYellow}/>)
        }
        else {
            starsArray.push(<Star key={i} filledPercentage={0} isYellow={isYellow}/>)
        }
    }

    return <div className={convertToModule(styles, "stars-array")}> {starsArray}</div>
}

export default ReviewStars;