import React from 'react'
import styles from './cardContent.module.scss';
import { convertToModule } from 'factory/utils/clientUtils';
import HK from 'factory/modules/HK';

const CardContainer = ({item}) => {
    return (
        <div className={convertToModule(styles, "item img_item")}>
            <div className={convertToModule(styles, "item content_item")} >
            <img className={convertToModule(styles, "start_comma")}  src={HK.getImage('/common/comma.svg')}  alt="start"/>
            {/* comma_end.svg */}
                <p className={convertToModule(styles, "testi_content")} >
                    {item.data.info}
                        <img className={convertToModule(styles, "end_comma")}  src={HK.getImage('/common/comma.svg')} alt="end" />
                </p>
                <p className={convertToModule(styles, "testi_name")}>{item.data.name}</p>
                <p className={convertToModule(styles, "testi_place")}>{item.data.place}</p>
            </div>
        </div>
    )
}

export default CardContainer