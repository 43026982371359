import React from "react";
import styles from './SideImageCTA.module.scss';
import { convertToModule } from "factory/utils/clientUtils";
import Link from 'components/Assets/LinkHref';
import HK from "factory/modules/HK";
import UniversalAnalytics from "factory/modules/UniversalAnalytics";

const SideImageCTA = (props) => {
    const handleUpdateEvent = () => {
        let data = {
            event_name: 'select_promotion',
            'reward_points_available': '',
            'value': '',
            'promotion_name': 'Refer and Earn',
            'creative_name': '',
            'creative_slot': ''
        }
        UniversalAnalytics.refer_and_earn(data);
    }
    return (
        <Link to="/refer-and-earn" className={convertToModule(styles, props.isPDP ? 'refer-link-wrapper-pdp' : 'refer-link-wrapper')} onClick={() => { handleUpdateEvent() }}>
            <img src={HK.getImage('/ReferAndEarn/ReferAndEarn.svg')} alt='refer&earn' className={convertToModule(styles, 'refer-earn-img-cntnr')} />
        </Link>
    )

}
export default SideImageCTA