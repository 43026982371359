import React, { Component } from 'react';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from "./VideoCard.module.scss";
import HK from 'factory/modules/HK';

class VideoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            play: false
        }
    }

    componentDidMount() {
        const { tabsData } = this.props;
        if (tabsData && tabsData.length > 0) {
            this.setState({ activeTab: tabsData[0].nm });
        }
    }

    tabClickHandler = (data) => {
        this.setState({ activeTab: data.nm });
        this.props.clickHandler(null, null, data);
    }

    handleVideoPlay = () => {
        this.setState({ play: true })
    }

    render() {
        const { vData } = this.props;
        return (<>{!HK.isMobile() ?

            <div className={convertToModule(styles, "video-card")}>
                <button className={convertToModule(styles, "videoPoster js-videoPoster")} onClick={this.handleVideoPlay} style={{
                    backgroundImage: `url(${vData.thumbnail})`,
                    backgroundRepeat: 'no-repeat',
                }}>
                    <svg className={convertToModule(styles, "play-vid")} viewBox="0 0 100 100">
                        <path className={convertToModule(styles, "stroke-solid")} fill="none" stroke="white" d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
         C97.3,23.7,75.7,2.3,49.9,2.5"></path>
                        <path className= {convertToModule(styles, "stroke-dotted")} fill="none" stroke="white" d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
         C97.3,23.7,75.7,2.3,49.9,2.5"></path>
                        <path className= {convertToModule(styles, "vid-icon")}  fill="white" d="M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z"></path>
                    </svg>
                    {
                        this.state.play &&
                        <video controls autoplay="" name="media" className={convertToModule(styles, "video-ele")} >
                            <source src={vData.video} type="video/mp4" />
                            Your browser does not support video playback.
                        </video>
                    }
                </button>
            </div>
            :
            <div className={convertToModule(styles, "video-cont")} onClick={this.handleVideoPlay}>
                <img src={vData.thumbnail} alt='thumbnail' />
                <svg className={convertToModule(styles, "play-vid")} viewBox="0 0 100 100">
                    <path className="stroke-solid" fill="none" stroke="white" d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
         C97.3,23.7,75.7,2.3,49.9,2.5"></path>
                    <path className="stroke-dotted" fill="none" stroke="white" d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
         C97.3,23.7,75.7,2.3,49.9,2.5"></path>
                    <path className="vid-icon" fill="white" d="M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z"></path>
                </svg>

                {this.state.play &&
                    <video controls autoplay="" name="media" className={convertToModule(styles, "video-ele")} >
                        <source src={vData.video} type="video/mp4" />
                        Your browser does not support video playback.
                    </video>}
            </div>
        }</>
        )
    }
}

export default VideoCard;