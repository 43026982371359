import React from "react";
import { convertToModule } from 'factory/utils/clientUtils';
import styles from './HomeStickyCTA.module.scss';
import HK from "factory/modules/HK";
import Link from 'components/Assets/LinkHref';
import UniversalAnalytics from "factory/modules/UniversalAnalytics";


const HomeStickyCTA = (props) => {
    const handleUpdateEvent = () => {
        let data = {
            event_name: 'select_promotion',
            'reward_points_available': '',
            'value': '',
            'promotion_name': 'Refer and Earn',
            'creative_name': '',
            'creative_slot': ''
        };
        UniversalAnalytics.refer_and_earn(data);
    };
    return (
        <div className={convertToModule(styles, props.isPDPCTACalled ? 'refre-home-cta-container-pdp' : 'refre-home-cta-container')} onClick={() => { handleUpdateEvent(); }}>
            <img src={HK.getImage('/ReferAndEarn/connect_icon.svg')} alt="connect_icon" className={convertToModule(styles, 'refer-cnct-img')} />

            <div className={convertToModule(styles, 'refer-earn-ttl')}>
                Refer a friend & earn rewards!
            </div>

            <Link to="/refer-and-earn" className={convertToModule(styles, 'refer-link-wrapper')}>
                <div className={convertToModule(styles, 'refer-now-btn')}>
                    Refer Now
                </div>
            </Link>

            <img src={HK.getImage('/ReferAndEarn/close_icon.svg')} alt="close_icon" onClick={() => props.handleCloseReferEarnPopup()} className={convertToModule(styles, 'close-popup-icon')} />
        </div>
    );
};

export default HomeStickyCTA;