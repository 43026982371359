import React, { Component } from 'react';
import HK from 'factory/modules/HK';
import Analytics from 'factory/modules/Analytics';
import { withRouter } from 'next/router';
// store
import { connect } from 'react-redux';
import { isLoginPopupAction } from 'components/common/login/action';
import { headerMenuAction } from 'components/common/header/action';
import { bindActionCreators } from 'redux';
import IntersectionObserver from 'components/common/IntersectionObserver';
import PDPEventTracker from '../../../components/Pdp/common/PDPEventTracker';
import { CARTAction } from 'components/common/header/action.js';
import UniversalAnalytics from '../../../factory/modules/UniversalAnalytics';
import styles from "components/ReviewData/ReviewHeader/reviewHeader.module.scss";
import { convertToModule } from 'factory/utils/clientUtils';
class BuyNow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buyNowPostLoginFlag: false,
            showLoader: false
        };
        this._doBuyNowCall = this._doBuyNowCall.bind(this);
    }
    doCall(event, eventName, eventCat, eventAction, eventLabel) {
        event.stopPropagation();
        event.preventDefault();
        localStorage.removeItem('packInfo');
        if (this.props.isPack) {
            let packdata = {
                packId: this.props.id,
                storeVariantIdsInPackStr: this.props.storeVariantId,
                quantity: this.props.quantity || 1
            }
            localStorage.setItem('packInfo', JSON.stringify(packdata));
        }
        if (!this.props.isLoggedin) {
            this.setState({
                buyNowPostLoginFlag: true
            });
            localStorage.setItem('buyNowPostLoginFlag', true)
            this.props._isLoginPopupAction({
                isShow: true,
                activeTab: 'login'
            });
        } else this._doBuyNowCall();
        let data = {
            'event': eventName,
            'eventCategory': eventCat,
            'eventAction': eventAction,
            'eventLabel': eventLabel,
        }
        HK._setDataLayer(data);
    }
    _doBuyNowCall() {
        let url = this.props.isPack ? HK.config.APIS.cart.buyPack : HK.config.APIS.cart.buyNow;
        HK.loader(true)

        let data;
        if (this.props.isPack) {
            data = {
                packId: this.props.id,
                storeVariantIdsInPackStr: this.props.storeVariantId,
                quantity: this.props.quantity || 1,
                userId: HK.loggedIn()
            }
        }
        else {
            data = {
                storeVariantId: this.props.id,
                vendorId: this.props.vendorID,
                quantity: this.props.quantity || 1,
                overrideQty: false,
                expressCheckout: true,
                userId: HK.loggedIn()
            };
        }
        HK.ajaxCall({
            url: url,
            method: 'post',
            data
        }).then(res => {
            if (res && res.data && res.data.results && !res.data.results.exception) {
                this.props._CARTAction(res.data.results.cartSummary);
                Analytics.Scope().buyNowAddTocart(this.props.varData, 'BuyNow', this.props.quantity || 1);
                UniversalAnalytics.PRESERVE_CLICKSTREAM_TO_STORAGE('BuyNow', this.props.varData);
                Analytics.dataLayerForCartUpdate(this.props.id, this.props.varData, this.props.isPack, "reactCartUpdateEvent", true);
                let loc = '/checkout?cartId=' + res.data.results.cartId + '&selectedAddressId=' + res.data.results.addressId + '&expressCheckout=true';
                if (this.props.isBuyNowFromCompare) {
                    this.props.isBuyNowFromCompare();
                }
                const clickStream = {
                    widgetName: 'Buy Now Click',
                    eventName: "BUY_NOW_CLICK",
                    brand: this.props.varData && this.props.varData.brName || "",
                    category: this.props.varData && this.props.varData.category || "",
                    itemName: this.props.varData && this.props.varData.name || "",
                    id: this.props.varData && this.props.varData.id || "",
                    offerPrice: this.props.varData && this.props.varData.offerPrice || 0,
                }
                if(this.props.eventData){
                    UniversalAnalytics.PRESERVE_ON_RELOAD().BUY_NOW_EVENT({...this.props.eventData,...{quantity:this.props.quantity || 1}});
                }
                if(this.props.customBuyNow && this.props.customBuyNow === true)
                {
                    if(typeof this.props.customBuyNowEvent === "function")
                    {
                        let tempClickStream,clickStreamData
                        if(this.props.customVarData)
                        {
                            tempClickStream = {
                                id: this.props.customVarData.id ? this.props.customVarData.id : "",
                                itemName: this.props.customVarData.name ? this.props.customVarData.name : "",
                                brand: this.props.customVarData.brName ? this.props.customVarData.brName : "",
                                offerPrice: this.props.customVarData.offerPrice ? this.props.customVarData.offerPrice : 0,
                                itemPosition: this.props.customVarData.itemPosition || 1
                            }
                        }
                        else
                        {
                            tempClickStream = {
                                id: clickStream.id,
                                itemName: clickStream.itemName,
                                brand: clickStream.brand,
                                offerPrice: clickStream.offerPrice,
                            }
                        }

                        if(this.props.customWidgetName && this.props.customWidgetName !== "")
                        {
                            clickStreamData = {
                                ...tempClickStream,
                                widgetName: this.props.customWidgetName ? this.props.customWidgetName : ""
                            }
                        }
                        else 
                        {
                            clickStreamData = {
                                ...tempClickStream
                            }
                        }

                        this.props.customBuyNowEvent({
                            ...clickStreamData,
                            qty: 1
                        })
                    }
                }
                else
                {
                    // HK.triggerComponentClickEvent(PDPEventTracker.getData(clickStream, this.props.reviewData));
                    UniversalAnalytics.PRESERVE_CLICKSTREAM_TO_STORAGE(HK.config.eventName.COMPONENT_CLICK, PDPEventTracker.getData(clickStream, this.props.reviewData));
                }
                // this.props.history.push(loc);
                window.location.href = loc;
            } else {
                HK.errorPopup('Could not process your request. Please try again later.', 'Got: exception::' + url);
                HK.reportErrorToDashboard({
                        stackTrace: new Error().stack,
                      });  
            } 
        }).catch(err => {
            HK.errorPopup('Could not process your request. Please try again later.', 'Got: ' + err + '::' + url);
HK.reportErrorToDashboard({
        stackTrace: new Error().stack,
        errorMessage: err
      });
        }).finally(() => {
            HK.loader()
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.isLoggedin && nextProps.isLoggedin && this.state.buyNowPostLoginFlag) //already called on callback of login
            this._doBuyNowCall();

    }
    componentDidMount() {
        if (localStorage.getItem('buyNowPostLoginFlag')) {
            localStorage.removeItem('buyNowPostLoginFlag');
            this._doBuyNowCall();
        }
    }
    triggerInViewEvent = (data) => {
        return (inView) => {
            if (!inView) return;
            try {
                const eventData = {
                    eventName: 'BUY_NOW',
                    widgetName: 'Buy Now',
                    brand: this.props.varData && this.props.varData.brName,
                    category: this.props.varData && this.props.varData.category,
                    itemName: this.props.varData && this.props.varData.name,
                    id: this.props.varData && this.props.varData.id,
                    offerPrice: this.props.varData && this.props.varData.offerPrice
                }
                HK.triggerComponentViewEvent(PDPEventTracker.getData(eventData, this.props.reviewData));
            } catch (err) {
                console.log(err)
            }
        }
    }
    render() {
        return (
            <IntersectionObserver className={convertToModule(styles,"hk-elements--buyNow")} onChange={this.triggerInViewEvent(this.props)}>
                <button type="button" className={convertToModule(styles,'track-add-to-cart addToCart tr-addPackToCart buyNownew ' + this.props.className)} onClick={(event) => this.doCall(event, this.props.event, this.props.eventCat, this.props.eventAct, this.props.varData.name)}>
                    {!this.props.isImage ? null : <img src={HK.getImage('/icons/flash.svg')} />}
                    {this.props.buttonDisplayName || (this.props.isCombo ? "Buy Combo" : this.props.isPdp ? HK.vernacular.variantNew_input_buynow[this.props.ln] : "Buy Now")}
                </button>
            </IntersectionObserver>
        )
    }
}
function mapStateToProps(state) {
    return {
        isLoggedin: state.isLogedInReducer
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        _isLoginPopupAction: isLoginPopupAction,
        _headerMenuAction: headerMenuAction,
        _CARTAction: CARTAction
    }, dispatch);
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BuyNow)
);