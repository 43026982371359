// import Slider from "react-slick";
import React, { Component } from 'react';
import HK from 'factory/modules/HK';
import styles from './desktopHomeSlider.module.scss';
import { convertToModule } from 'factory/utils/clientUtils';
import Itrack from 'utility/Itrack';
import IntersectionObserver from '../../../common/IntersectionObserver'
import UniversalAnalytics from 'factory/modules/UniversalAnalytics.js'
// import OwlCarousel from 'utility/templates/owlCarousel';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Pagination, Navigation, Autoplay
} from 'swiper/core';
import { Thumbs } from 'swiper';
import dynamic from 'next/dynamic';
import ReferVars from 'components/ReferAndEarn/referandearnconfig';
import Link from 'components/Assets/LinkHref';
import SideImageCTA from 'components/ReferAndEarn/SideImageCTA';
const Swiper2 = dynamic(() => import('swiper/react').then(module => module.Swiper), { ssr: false });
// install Swiper modules
SwiperCore.use([Thumbs]);
SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);
export default class DesktopHomeSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            widgetName: "Carousel Banner",
            eventName: "CAROUSEL_BANNER",
            miniWidget: "Mini Carousel Banner",
            miniEvent: "MINI_CAROUSEL_BANNER",
            autoplay: false,
            secondSwiper: null,
            initialSlide: 0
        };
    }

    setSecondSwiper = (instance) => {
        this.setState({ secondSwiper: instance });
    }

    setInitialSlide = (slide) => {
        this.setState({ initialSlide: slide });
    }

    updateDatalayer = (eventAction, item, k, flag) => {
        UniversalAnalytics.BANNER_CLICK(item, k + 1);
        let data = {
            'event': 'Is_MB_Home-Banners',
            'eventCategory': 'Home Page Banners',
            'eventAction': eventAction,
            'eventLabel': "",
        }
        HK._setDataLayer(data);
        let EventName = {
            'event': 'LS_MB_promotionClick',
            'ecommerce': {
                'promoClick': {
                    'promotions': [
                        {
                            'id': "",
                            'name': eventAction,
                            'creative': "",
                            'position': k + 1
                        }]
                }
            },
        }
        window.dataLayer.push(EventName)
        let clickStream = {
            widgetName: flag ? this.state.miniWidget : this.state.widgetName,
            position: k + 1,
            eventName: flag ? this.state.miniEvent : this.state.eventName,
            itemName: item.title,
            landingUrl: item.link
        }
        HK.triggerComponentClickEvent(clickStream)
    }

    triggerInViewEvent = (banner, index, flag) => {

        return (inView, entry) => {
            if (!inView) return;

            const eventData = {
                widgetName: flag ? this.state.miniWidget : this.state.widgetName,
                itemName: banner.title,
                position: index + 1,
                eventName: flag ? this.state.miniEvent : this.state.eventName,
                landingUrl: banner.link
            }
            if (eventData && eventData.eventName) {
                HK.triggerComponentViewEvent((eventData))
            }
        }
    }

    render() {
        if (!this.props.items) return null;

        return (
            <section className={convertToModule(styles, "desktop-home-slider")}>
                <div className={convertToModule(styles, "slider-container")}>
                    <div className={convertToModule(styles, "hk-swiper")}>
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={0}
                            slidesPerGroup={1}
                            loop={true}
                            loopFillGroupWithBlank={true}
                            pagination={false}
                            navigation={true}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                                stopOnLastSlide: false,
                                pauseOnMouseEnter: false
                            }}
                            speed={750}
                            className={"home-slider-D"}
                            // observer={false}
                            // observeParents={true}
                            initialSlide={this.state.initialSlide}
                            centeredSlides={false}
                            breakpoints={false}
                        // thumbs={{ swiper: this.state.secondSwiper }}
                        >
                            {
                                this.props.items.map((item, k) => {
                                    return (
                                        <SwiperSlide key={k}>
                                            {
                                                item.link ?
                                                    <IntersectionObserver className={convertToModule(styles, "banner-item")} key={k} onChange={this.triggerInViewEvent(item, k, false)}>
                                                        <Itrack classname={convertToModule(styles, "banner-item")} key={k} onClick={this.updateDatalayer.bind(this, item.title, item, k, false)}
                                                            ind={k + 1} isLink={HK.isLink(item.link)} isFullUrl url={item.link} id={item.id} childOf={'top-banner-slider'} >
                                                            <img className={convertToModule(styles, "banner-img")} src={item.img} alt={item.title} fetchpriority={k === 0 ? "high" : "auto"} />
                                                        </Itrack>
                                                    </IntersectionObserver>
                                                    :
                                                    <IntersectionObserver className={convertToModule(styles, "banner-item")} key={k} onChange={this.triggerInViewEvent(item, k, false)} onClick={this.updateDatalayer.bind(this, item.title, item, k, false)}>
                                                        <img className={convertToModule(styles, "banner-img")} src={item.img} alt={item.title} fetchpriority={k === 0 ? "high" : "auto"} />
                                                    </IntersectionObserver>
                                            }
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>

                    {/* <div className={convertToModule(styles, "hk-swiper hm-swpr-dsk-cnt-small")}>
                        <Swiper2
                            slidesPerView={3}
                            spaceBetween={0}
                            slidesPerGroup={1}
                            loop={true}
                            loopFillGroupWithBlank={true}
                            pagination={false}
                            navigation={false}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                                stopOnLastSlide: false,
                                pauseOnMouseEnter: false
                            }}
                            speed={750}
                            className={"home-slider-D2"}
                            // observer={false}
                            // observeParents={true}
                            initialSlide={0}
                            centeredSlides={false}
                            breakpoints={false}
                            watchSlidesProgress
                            onSwiper={this.setSecondSwiper}
                        >
                            {
                                this.props.items.map((item, k) => {
                                    return (
                                        <SwiperSlide key={k}>
                                            <IntersectionObserver className={convertToModule(styles, "nav-item")} key={k} onChange={this.triggerInViewEvent(item, k, true)} onClick={this.updateDatalayer.bind(this, item.title, item, k, true)}>
                                                <img src={item.img} alt={item.title} onClick={() => this.setInitialSlide(k)} />
                                            </IntersectionObserver>
                                        </SwiperSlide>

                                    )
                                })
                            }
                        </Swiper2>
                    </div> */}
                    {
                        !HK.isMobile() && ReferVars.Visibility.isBannerWeb ?
                           <SideImageCTA/>
                            : <></>
                    }
                </div>

            </section>
        );
    }
}
