import HK from 'factory/modules/HK'
import React ,{useEffect}from 'react';
import styles from './previewModal.module.scss';
import { convertToModule } from 'factory/utils/clientUtils';

const PreviewModal = ({ data, closeModal, setActiveIndex, activeIndex, dataLen }) => {
    const isMobile = HK.isMobile() ? "mweb" : "";

    useEffect(()=>{
        HK.stopScroll(true);
        return ()=>{
            HK.stopScroll(false);
        };
        },[]);
    const handleNavigation = (flag) => {
        if (flag === 'left') {
            if (activeIndex === 0) return;
            setActiveIndex(activeIndex - 1);
        } else {
            if ((dataLen - 1) === activeIndex) return;
            setActiveIndex(activeIndex + 1)
        }

    }
    return (
        <div className={convertToModule(styles, `preview-modal ${isMobile}`)}>
            <div className={convertToModule(styles, `preview-container`)}>
                <div className={convertToModule(styles, `left`)}>
                    {data && data.img2 ? <img src={data && data.img2} alt="img" /> :''}
                </div>
                <div className={convertToModule(styles, `right`)}>
                    <div className={convertToModule(styles, `header`)}>
                        <img src="https://instafeed.nfcube.com/assets/img/logo-instagram-transparent.png" className={convertToModule(styles, `insta-logo`)} alt="insta-logo" />
                        <p>Gritzo India</p>
                        <img src={HK.getImage('/Gritzo/Common/close.svg')} onClick={closeModal} className={convertToModule(styles, `close-btn`)} alt="close" />
                    </div>
                    <div className={convertToModule(styles, `caption`)}>
                        <div className={convertToModule(styles, `navigation`)}>
                            <img src={HK.getImage('/Gritzo/Common/arrow-right-light.svg')} alt="left" onClick={() => handleNavigation('left')} />
                            <img src={HK.getImage('/Gritzo/Common/arrow-right-light.svg')} alt="right" onClick={() => handleNavigation('right')} />
                        </div>
                        <p className={convertToModule(styles, `text`)}>{data && data.caption}</p>
                        <div className={convertToModule(styles, `footer`)}>
                            <span>{data && data.date}</span> • <a href={data && data.link} target='_blank'>View on Instagram</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewModal